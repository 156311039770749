import styled from 'styled-components'

import { Container, Icon, Tag, Text } from 'common/UI'
import { getValidIcon } from 'common/UI/Icon'
import useDrawer from 'common/hooks/useDrawer'
import { DynamicFormStepsStoryblok, DynamicFormStoryblok } from 'common/types'
import { textByLine } from 'common/utils/content'
import { addAlphaToColor, dashedLine } from 'common/utils/style'

type Props = {
  content: DynamicFormStoryblok | DynamicFormStepsStoryblok
}

export const DynamicFormHeader = ({ content }: Props): JSX.Element => {
  const { drawer } = useDrawer()
  const isDrawer = !!drawer

  const { tag, title, description, details } = content

  return (
    <Header variant={isDrawer ? 'full' : 'typography'} isDrawer={isDrawer}>
      {tag && <Tag label={tag} />}

      {title && (
        <HeaderTitle as="p" variant="title/large">
          {title}
        </HeaderTitle>
      )}

      {description &&
        textByLine(description, (part) => (
          <Text
            as="p"
            variant="eighteen"
            css={{
              marginTop: '1rem',
              marginBottom: details?.length ? '1.25rem' : '0',
            }}
          >
            {part}
          </Text>
        ))}

      <EventDetails>
        {details?.map((detail) => {
          const typedIcon = getValidIcon(detail.icon)
          return (
            <EventDetail key={detail._uid}>
              {detail.icon && typedIcon && (
                <Icon size={20} icon={typedIcon} styles={{ opacity: '60%' }} />
              )}
              <Text as="p" variant="sixteen/regular">
                {detail.text}
              </Text>
            </EventDetail>
          )
        })}
      </EventDetails>

      <Divider />
    </Header>
  )
}

const Header = styled(Container)<{ isDrawer: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  padding-top: 1.25rem;

  ${({ theme }) => theme.media.md} {
    padding-top: ${({ isDrawer }) => (isDrawer ? '1.25rem' : '3.75rem')};
  }
`

const HeaderTitle = styled(Text)`
  margin-top: 1rem;
`

const EventDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;

  margin-top: 1.25rem;
`
const EventDetail = styled.div`
  display: flex;
  gap: 0.25rem;

  align-items: center;
  justify-content: center;
`

const Divider = styled.div`
  height: 1px;
  margin-top: 2rem;
  margin-bottom: 2rem;

  ${({ theme }) =>
    dashedLine('bottom', addAlphaToColor(theme.colors.foreground.default, 60))};
`
